<template>
  <vs-card>
      <h4 class="mb-6 ml-5 mt-2">Question Details</h4>
      <form autocomplete="off">
        <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <label class="label" name="question-label">Question</label>
              <vs-textarea
                :success="!errors.first('basic.question') && questionnaire.question!=''"
                val-icon-success="done"
                val-icon-danger="error"
                :danger="(errors.first('basic.question') ? true : false)"
                v-validate="'required'"
                name="question"
                data-vv-as="Question"
                class="w-full"
                data-vv-scope="basic"
                :danger-text="errors.first('basic.question')"
                v-model="questionnaire.question"
              />
            </div>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <label class="label" name="treatment-type">Treatment type</label>
              <v-select multiple :closeOnSelect="false"
                        label="name"
                        :options="treatments"
                        v-model="selectedTreatment"
                        name="treatmentId"
                        class="w-full"
              />
            </div>
          </vs-col>
          <!--<vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="m-5">
              <vs-checkbox
                :success="!errors.first('basic.shouldShowToAll') && questionnaire.shouldShowToAll!=''"
                val-icon-success="done"
                val-icon-danger="error"
                :danger="(errors.first('basic.shouldShowToAll') ? true : false)"
                name="shouldShowToAll"
                data-vv-as="shouldShowToAll"
                data-vv-scope="basic"
                :danger-text="errors.first('basic.shouldShowToAll')"
                v-model="questionnaire.shouldShowToAll"
                type="checkbox"
              >
                Show To All
              </vs-checkbox>
            </div>
          </vs-col>-->
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="m-5">
              <vs-checkbox
                :success="!errors.first('basic.isEnabled') && questionnaire.isEnabled!=''"
                val-icon-success="done"
                val-icon-danger="error"
                :danger="(errors.first('basic.isEnabled') ? true : false)"
                name="isEnabled"
                data-vv-as="isEnabled"
                data-vv-scope="basic"
                :danger-text="errors.first('basic.isEnabled')"
                v-model="questionnaire.isEnabled"
                type="checkbox"
              >
                Enable
              </vs-checkbox>
            </div>
          </vs-col>
          <vs-col>
            <div class="w-full m-5">
              <label class="label" style="font-size: 1rem;">Is this question mandatory?</label>
              <ul class="leftx" style="font-size: 1rem;">
                <li>
                  <vs-radio
                    v-model="questionnaire.is_mandatory" vs-name="mandatory"
                    vs-value="yes">Yes
                  </vs-radio>
                </li>
                <li>
                  <vs-radio v-model="questionnaire.is_mandatory" vs-name="mandatory"
                    vs-value="no">No
                  </vs-radio>
                </li>
              </ul>
            </div>
          </vs-col>
          <vs-col >
            <div class="m-5">
              <vs-checkbox
                :success="!errors.first('basic.populateTreatmentPlans') && questionnaire.populateTreatmentPlans!=''"
                val-icon-success="done"
                val-icon-danger="error"
                :danger="(errors.first('basic.populateTreatmentPlans') ? true : false)"
                name="populateTreatmentPlans"
                data-vv-as="populateTreatmentPlans"
                data-vv-scope="basic"
                :danger-text="errors.first('basic.populateTreatmentPlans')"
                v-model="questionnaire.populateTreatmentPlans"
                type="checkbox"
              >
                Populate Treatment Plans
              </vs-checkbox>
            </div>
          </vs-col>
          <vs-col>
            <div class="w-full m-5">
              <vs-button
                size="normal"
                @click="saveQuestionnaireDetails"
                icon-pack="feather"
                class="mr-2"
              >Update Question</vs-button>
            </div>
          </vs-col>
        </vs-row>
      </form>
  </vs-card>
</template>

<script>

  import { mapActions } from 'vuex';
  import vSelect from "vue-select";

  export default {
    name: 'questionnaireViewEditDetail',
    components: {
      'v-select': vSelect,
    },
    data:()=>({
      questionnaire:{
        question:'',
        shouldShowToAll:'',
        isEnabled: '',
        treatmentId: '',
        is_mandatory: "no"
      },
      treatments:[{
        _id: '',
        isAvailableToAll: '',
        name: '',
        description: ''
      }],
      selectedTreatment:[{
        _id: '',
        isAvailableToAll: '',
        name: '',
        description: ''
      }]

    }),
    methods:{
      ...mapActions('questionnaire', ['fetchQuestionnaireDetails','updateQuestionnaireDetails', 'fetchTreatmentPlans']),
      async getTreatmentPlans(){
        try {
          const res = await this.fetchTreatmentPlans()
          this.treatments = res.data.data;
        } catch (error) {
          console.log('error fetching treatments ' + error)
        }
      },
      getQuestionnaireDetail(id) {
        let self = this;
        this.fetchQuestionnaireDetails(id).then(res => {
          this.questionnaire.question = res.data.data.question
          this.questionnaire.shouldShowToAll = res.data.data.shouldShowToAll
          this.questionnaire.isEnabled = res.data.data.isEnabled
          this.questionnaire.treatmentId = res.data.data.treatmentId;
          this.questionnaire.is_mandatory = res.data.data.is_mandatory ? "yes" : "no";
          this.questionnaire.populateTreatmentPlans = res.data.data.populateTreatmentPlans;

          let treatments = this.treatments;
          let selectedTreatments = [];
          this.questionnaire.treatmentId.forEach(function (item) {
            treatments.forEach(function (treatmentItem) {
              if(item.id == treatmentItem._id){
                selectedTreatments.push(treatmentItem);
              }
            })
          })
          this.selectedTreatment = selectedTreatments;
        })
      },
      saveQuestionnaireDetails(){
        let id = this.$route.params.id;

        let treatmentIds = [];
        this.selectedTreatment.forEach(function (item) {
          if(item._id){
            treatmentIds.push({'id': item._id});
          }
        });
        this.questionnaire.treatmentId = treatmentIds;
        this.questionnaire.is_mandatory = this.questionnaire.is_mandatory === "yes"

        let info = this.questionnaire;
        this.updateQuestionnaireDetails({id,info}).then(res => {
          this.$vs.notify({
            title: 'Update Consent Questionnaire',
            text: res.data.message,
            color: 'success'
          })

          this.$router.push("/super-admin/questionnaire");
        });
      }
    },
    async created() {
      let pageId = this.$route.params.id;
      await this.getTreatmentPlans();
      await this.getQuestionnaireDetail(pageId);
    }
  }
</script>
<style>
  .ql-editor{
    min-height:200px;
  }
  .label {
    font-size: small;
    color: black;
  }
</style>
